import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

var locale = Cookies.get('locale')
const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
if (typeof locale == 'undefined' || !locales.keys().includes("./" + locale + ".json")) {
  locale = 'en'
  Cookies.set('locale', locale, { expires: 365 })
}

export default new VueI18n({
  locale: locale || 'en',
  fallbackLocale: locale || 'en',
  messages: loadLocaleMessages()
})
