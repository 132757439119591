import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'

import '@/styles/builder.css'
import '@/styles/base.css'

Vue.config.productionTip = false
Vue.use(VueToast)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
