import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index/index.vue'),
  },
  {
    path: '/404',
    component: () => import('@/views/error_page/404.vue'),
  },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  // mode: 'history', // require service support
  routes
})

export default router
