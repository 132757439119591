import Vue from 'vue'
import Vuex from 'vuex'
import builder from './modules/builder'
import mypage from './modules/mypage'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
      builder,
      mypage
    },
    strict: process.env.NODE_ENV !== 'production'
})