import Cookies from 'js-cookie'

const state = () => ({
    builder_mode: true,
    preview_mode: false,
    tab_settings: 'nav-2',
    language: 'en',
    new_user: Cookies.get('new_user') ? (Cookies.get('new_user') === 'true') : false,
})

const mutations = {
    SET_BUILDER_MODE (state, value){
        state.builder_mode = value
    },
    SET_PREVIEW_MODE (state, value){
        state.preview_mode = value
    },
    SET_TAB_SETTINGS (state, value){
        state.tab_settings = value
    },
    SET_LANGUAGE (state, value){
        state.language = value
        Cookies.set('locale', value, { expires: 365 })
    },
    SET_NEW_USER (state, value){
        state.new_user = value
        Cookies.set('new_user', value, { expires: 365 })
    }
}
const actions = {
    setBuilderMode({ commit }, value) {
        if (value === process.env.VUE_APP_BUILDER_DOMAIN || value === "builder" || value.includes("localhost")){
            commit('SET_BUILDER_MODE', true)
        }else{
            commit('SET_BUILDER_MODE', false)
        }
    },
    setPreviewMode({ commit }, value) {
        commit('SET_PREVIEW_MODE', value)
    },
    setTabSettings({ commit }, value) {
        commit('SET_TAB_SETTINGS', value)
    },
    setNewUser({ commit }, value) {
        commit('SET_NEW_USER', value)
    }
}

const getters = {
    builderMode: state => {
      return state.builder_mode
    },
    previewMode: state => {
      return state.preview_mode
    },
    tabSettings: state => {
      return state.tab_settings
    },
    getLanguage: state => {
        return state.language
    },
    getNewUser: state => {
        return state.new_user
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}