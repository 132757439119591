import utils from "@/utils/utils.js"
import Vue from "vue"
const state = () => ({
    settings:{},
    block_ids: '', //string contains block id, seperated by commas, as order of blocks
    blocks: [],
    page_id: 0,
    subdomain: '',
    current_domain: '',
    domain_list: [],
    page_name: '',
    page_description: '',
    page_favicon: ''
})
const mutations = {
    SET_PAGE_ID (state, value){
        state.page_id = value
    },
    SET_CURRENT_DOMAIN (state, value){
        state.current_domain = value
    },
    SET_SUBDOMAIN (state, value){
        state.subdomain = value
    },
    SET_DOMAIN_LIST (state, value){
        state.domain_list = value
    },
    SET_PAGE_NAME (state, value){
        state.page_name = value
    },
    SET_PAGE_DESCRIPTION (state, value){
        state.page_description = value
    },
    SET_PAGE_FAVICON (state, value){
        state.page_favicon = value
    },
    SET_BLOCK_IDS (state, value){
        state.block_ids = value
    },
    SET_BLOCKS (state, value){
        state.blocks = []
        state.blocks.push(...value)
        updateBlockIds(state)
    },
    ADD_BLOCK (state, payload){
        state.blocks.splice(payload.index, 0, payload.value)
        updateBlockIds(state)
    },
    UPDATE_BLOCK (state, payload){
        for(var i = 0; i < payload.update_data.length ; i++){
          var item = payload.update_data[i]
          state.blocks[payload.block_index][item.key] = item.value
        }
    },
    UPDATE_BLOCK_BY_KEY (state, payload){
        state.blocks[payload.block_index][payload.key] = payload.value
    },
    DELETE_BLOCK (state, index){
        state.blocks.splice(index, 1)
        updateBlockIds(state)
    },
    SWAP_BLOCK (state, payload){
        state.blocks.splice(payload.from, 1, state.blocks.splice(payload.to, 1, state.blocks[payload.from])[0])
        updateBlockIds(state)
    },
    UPDATE_SETTINGS (state, payload){
        for(var i = 0; i < payload.update_data.length ; i++){
            var item = payload.update_data[i]
            Vue.set(state.settings, item.key, item.value)
        }
        utils.updateCSSVariables(state.settings)
    }
}
function updateBlockIds(state){
    var array_block_id = []
    for(var i = 0; i < state.blocks.length; i++){
      array_block_id.push(state.blocks[i].block_id)
    }
    state.block_ids = array_block_id.join(',')
}
const actions = {
    setBlocks({commit}, value){
        commit('SET_BLOCKS', value)
    }
}
const getters = {
    getPageId: state => {
        return state.page_id
    },
    getSubdomain: state => {
        return state.subdomain
    },
    getCurrentDomain: state => {
        return state.current_domain
    },
    getDomainList: state => {
        return state.domain_list
    },
    getPageName: state => {
        return state.page_name
    },
    getPageDescription: state => {
        return state.page_description
    },
    getPageFavicon: state => {
        return state.page_favicon
    },
    getBlocks: state => {
        return state.blocks
    },
    getBlockIds: state => {
        return state.block_ids
    },
    numberOfBlocks: state => {
        return state.blocks.length
    },
    getSettings: state => {
        return state.settings
    }
}
export default {
    namespaced: true,state,getters,actions,mutations
}